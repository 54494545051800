/** @jsx jsx */
import { Fragment } from 'react'
import DateFormat from './date-formater'
import ReactMarkdown from 'react-markdown'
import { jsx } from '@emotion/react'

import {
  singleBlog,
  blogThumb,
  blogHeadings,
  blogMetas,
  blogDetails,
} from '../assets/styles/SinglePostCard.styles'
import { Container } from 'react-bootstrap'

const SinglePostCard = (props) => {
  const { post, url } = props

  return (
    <Fragment>
      <Container>
        <div css={singleBlog}>
          <div css={blogHeadings}>
            <h2>{post?.attributes?.titulo}</h2>
            <p css={blogMetas}>
              <a href='#'>Publicado por {post?.attributes?.autor}</a>
              <p>
                <DateFormat createdDate={post?.attributes?.createdAt} />
              </p>
            </p>
          </div>
          <Container>
            <div css={blogThumb}>
              {post?.attributes?.imagem_destaque && (
                <img
                  src={
                    url + post?.attributes?.imagem_destaque?.data?.attributes?.url
                  }
                  alt={post?.attributes?.imagem_destaque?.data?.attributes?.name}
                />
              )}
            </div>
          </Container>
          <div css={blogDetails}>
            <ReactMarkdown>{post?.attributes?.conteudo}</ReactMarkdown>
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default SinglePostCard

/** @jsx jsx */
import { Container, Row, Col } from "react-bootstrap";
import SinglePostCard from "@/components/single-post-card";
import { jsx } from '@emotion/react'

import { commonSection }  from '../assets/styles/layout.styles'

const api_url = "https://strapi-app-pecfr.ondigitalocean.app"

const BlogDetails = (props) => {

  const { post } = props

  return (
    <section css={commonSection} className="blogDetails">
      <Container>
        <Row>
          <Col lg={12}>
            <SinglePostCard url={api_url} post={post} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BlogDetails;